import {
    GET_JOB_LIST_FAIL,
    GET_JOB_LIST_SUCCESS,
    ADD_NEW_PORTFOLIO,
    ADD_PORTFOLIO_SUCCESS,
    ADD_PORTFOLIO_FAIL,
    UPDATE_JOB_LIST_SUCCESS,
    UPDATE_JOB_LIST_FAIL,
    DELETE_JOB_LIST_SUCCESS,
    DELETE_JOB_LIST_FAIL,
    GET_APPLY_JOB_SUCCESS,
    GET_APPLY_JOB_FAIL,
    DELETE_APPLY_JOB_SUCCESS,
    DELETE_APPLY_JOB_FAIL,
    GET_JOB_LIST,
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAIL,
    SET_PROPERTY_FOR_DETAILS,
    GET_PROPERTY_FOR_DETAILS,
    BACK_TO_PROPERTIES,
    ADD_TO_CHATS,
    ADD_TO_CHATS_FAIL,
    ADD_TO_CHATS_SUCCESS,
    RESET_CHATS,
    RESET_TOAST,
    RESERVE_PROPERTY_ONE,
    RESERVE_PROPERTY_TWO,
    GET_RESERVED_PROPERTIES
} from "./actionTypes";

const INIT_STATE = {
    jobs: [],
    jobData: {},
    userData: {},
    property: {},
    error: {},
    chat: null,
    jobApply: [],
    page: 1,
    setPage: false,
    loading: false,
    showToast: false,
    reservePropertyOne: null,
    reservePropertyTwo: null
}

const JobReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESERVE_PROPERTY_ONE:
            return {
                ...state,
                reservePropertyOne: action.payload
            };

        case RESERVE_PROPERTY_TWO:
            return {
                ...state,
                reservePropertyTwo: action.payload
            };

        case GET_RESERVED_PROPERTIES:
            return {
                ...state,
                loading: false
            };

        case ADD_TO_CHATS:
            return {
                ...state,
                payload: action.payload,
                loading: true
            };

        case RESET_CHATS:
            return {
                ...state,
                chat: null
            };

        case RESET_TOAST:
            return {
                ...state,
                error: null,
                showToast: false,
                loading: false
            };

        case ADD_TO_CHATS_SUCCESS:
            return {
                ...state,
                chat: action.payload,
                loading: false
            };

        case ADD_TO_CHATS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case BACK_TO_PROPERTIES:
            return {
                ...state,
                setPage: action.setPage
            };

        case SET_PROPERTY_FOR_DETAILS:
            return {
                ...state,
                property: action.payload.item,
                page: action.payload.currentPage
            };

        case GET_PROPERTY_FOR_DETAILS:
            return {
                ...state,
                loading: false
            };

        case GET_JOB_LIST:
            return {
                ...state,
                loading: true
            };

        case GET_JOB_LIST_SUCCESS:
            return {
                ...state,
                jobData: action.payload,
                setPage: false,
                loading: false
            };

        case GET_JOB_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                setPage: false,
                loading: false
            };

        case GET_USER_LIST:
            return {
                ...state,
                loading: true
            };

        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                loading: false
            };

        case GET_USER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case ADD_NEW_PORTFOLIO:
            return {
                ...state,
                loading: true,
                showToast: false
            };

        case ADD_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loading: false,
                showToast: true
            };

        case ADD_PORTFOLIO_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                showToast: true
            };

        case UPDATE_JOB_LIST_SUCCESS:
            return {
                ...state,
                jobs: state.jobs.map(job =>
                    job.id.toString() === action.payload.id.toString()
                        ? { job, ...action.payload }
                        : job
                ),
            };

        case UPDATE_JOB_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_JOB_LIST_SUCCESS:
            return {
                ...state,
                jobs: state.jobs.filter(
                    job => job.id.toString() !== action.payload.toString()
                ),
            };

        case DELETE_JOB_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case GET_APPLY_JOB_SUCCESS:
            return {
                ...state,
                jobApply: action.payload,
                loading: true
            };
        case GET_APPLY_JOB_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_APPLY_JOB_SUCCESS:
            return {
                ...state,
                jobApply: state.jobApply.filter(data => data.id.toString() !== action.payload.toString())
            };
        case DELETE_APPLY_JOB_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state
    }
}

export default JobReducer;