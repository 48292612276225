import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import moment from 'moment';

//import images
import wechat from "../../../assets/images/companies/wechat.svg";

const DetailsSection = (props) => {
    return (
        <React.Fragment>
            <Col xl={7}>
                <Card>
                    <CardBody className="border-bottom">
                        <div className="text-center">
                            <img className="img-fluid" src={props.property.imgSrc} alt="" />
                        </div>
                        <div className=''>
                            <img src="https://www.zoopla.co.uk/static/images/mashery/powered-by-zoopla-150x73.png" width="60" height="30" title="Property information powered by Zoopla" alt="Property information powered by Zoopla" border="0" />
                        </div>
                    </CardBody>
                    <CardBody>
                        <p className="text-muted">Click here for more details: </p><a href={props.property.detailUrl} target="_blank" rel="noreferrer">{props.property.detailUrl}</a>
                        <br /><br />
                        <h5 className="fw-semibold mb-3">Description</h5>
                        <p className="text-muted">{props.property.description}</p>
                        <p className="text-muted">{props.property.shortDescription}</p>

                        {/* <h5 className="fw-semibold mb-3">Market Stats</h5>
                        <ul className="list-unstyled mt-4">
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-calculator text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Matrices</h6>
                                        <p className="text-muted fs-14 mb-0">Return on Investment (ROI): {props.property.grossYield}</p>
                                        <p className="text-muted fs-14 mb-0">Asking Rent: {props.property.rent}</p>
                                        <p className="text-muted fs-14 mb-0">Cash Flow: {props.property.cashFlow}</p>
                                        <p className="text-muted fs-14 mb-0">Cash on Cash Return: {props.property.cashAndCashReturn}%</p>
                                        <p className="text-muted fs-14 mb-0">Loan-to-Value Ratio (LTV - 20% down): {props.property.loanToValueRatio * 100}%</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    {props.property.currency === 'USD' ? <i className="bx bx-dollar text-primary fs-4"></i> : <i className="bx bx-euro text-primary fs-4"></i>}
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Total Price</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.price}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bxs-info-circle text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Agent Information</h6>
                                        <p className="text-muted fs-14 mb-0">Agent Id: {props.property.companyId}</p>
                                        <p className="text-muted fs-14 mb-0">Agent Address: {props.property.agentAddress}</p>
                                        <p className="text-muted fs-14 mb-0">Agent Phone #: {props.property.agentPhone}</p>
                                    </div>
                                </div>
                            </li>
                            {props.property.priceChange && props.property.priceChange.length && props.property.priceChange.length > 0 && <li className="mt-3">
                                <div className="d-flex">
                                    <i className="fas fa-sort-amount-down text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Price Changes</h6>
                                        <p className="text-muted fs-14 mb-0">01- Price change since {moment(props.property.priceChange[0].date).format('MMMM Do YYYY')} is {props.property.priceChange[0].direction === "" ? "staying at " : props.property.priceChange[0].direction === "up" ? "increased by " : "decreased by "} {props.property.priceChange[0].percent} with the value {props.property.priceChange[0].price}.</p>
                                    </div>
                                </div>
                            </li>}
                        </ul> */}
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default DetailsSection;