import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const SerialNo = (cell) => {
    let index = cell.row && cell.row.index ? cell.row.index : 0;
    return index + 1;
};

const FirstName = (cell) => {
    return cell.value ? cell.value : "";
};

const LastName = (cell) => {
    return cell.value ? cell.value : "";
};

const Role = (cell) => {
    return cell.value ? cell.value : "";
};

const Level = (cell) => {
    return cell.value ? cell.value : "";
};

const YesOrNo = (cell) => {
    return cell.value ? 'Yes' : 'No';
};

const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const MemberSince = (cell) => {
    return cell.value ? moment(cell.value).format('MMMM Do YYYY') : "";
};

const Amount = (cell) => {
    return cell.value ? '$' + cell.value : '$0';
};

const Status = (cell) => {
    switch(cell.value) {
        case true:
            return <Badge className="bg-success">Active</Badge>
        case false:
            return <Badge className="bg-danger">Archived</Badge>
    }
};


export { SerialNo, FirstName, LastName, Role, Level, YesOrNo, Type, MemberSince, Amount, Status };