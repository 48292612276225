import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal, ModalBody, ModalHeader,
  Label, Input, Form,
  FormFeedback,
  Spinner
} from "reactstrap";

import { Link, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import classnames from "classnames";
import img1 from "../../../assets/images/small/img-2.jpg";
import img2 from "../../../assets/images/small/img-6.jpg";
import img3 from "../../../assets/images/small/img-1.jpg";

import Paginations from 'components/Common/Pagination';

import moment from "moment";

import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';

import { getPortfolio, addToPortfolio, resetPortfolioToast } from "../../../store/actions";

const BlogGrid = () => {
  const [modal, setModal] = useState(false);
  const [portfolio, setPortfolio] = useState({});
  const [portfolios, setPortfolios] = useState([]);
  const [activeTab, toggleTab] = useState("1");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectBlogReducerState = (state) => state.blog;
  const PortfolioProperties = createSelector(selectBlogReducerState, (blogReducer) => ({
    error: blogReducer.error,
    loading: blogReducer.loading,
    portfolioData: blogReducer.portfolioData,
    page: blogReducer.page,
    setPage: blogReducer.setPage,
    showToast: blogReducer.showToast
  }));

  const { error, loading, portfolioData, page, setPage, showToast } = useSelector(PortfolioProperties);

  const [currentPage, setCurrentPage] = useState(setPage ? page : 1);
  const perPageData = 6;

  function addToFavorite() {
    // if (item.grossYield) item.yield = item.grossYield.split('%')[0];
    // setPortfolio(item);
    setModal(true);
  }

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: portfolio.address || '',
      propertyType: portfolio.propertyType || '',
      floors: portfolio.numFloors || 1,
      bedrooms: portfolio.bedrooms || 1,
      bathrooms: portfolio.bathrooms || 1,
      uniqueFeature: portfolio.category || '',
      purchasePrice: parseFloat(portfolio.price) || 0,
      value: parseFloat(portfolio.price) || 0,
      rent: portfolio.rent || 0,
      grossYield: parseFloat(portfolio.yield) || 0,
      expenses: 0,
      occupancyRate: 0,
      renovations: '',
      futurePlans: ''
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Please Enter The Address"),
      propertyType: Yup.string().required("Please Enter The Property Type"),
      floors: Yup.string().required("Please Enter The Number Of Floors"),
      bedrooms: Yup.string().required("Please Enter The Number Of Bedrooms"),
      bathrooms: Yup.string().required("Please Enter The Number Of Bathrooms"),
      uniqueFeature: Yup.string().required("Please Enter Any Unique Feature"),
      purchasePrice: Yup.string().required("Please Enter The Purchase Price"),
      value: Yup.string().required("Please Enter The Current Market Value"),
      rent: Yup.string().required("Please Enter The Rent"),
      grossYield: Yup.string().required("Please Enter The Gross Yield"),
      expenses: Yup.string().required("Please Enter The Expenses Per Month"),
      occupancyRate: Yup.string().required("Please Enter The Occupancy Rates"),
      renovations: Yup.string().required("Please Enter The Recent Renovations"),
      futurePlans: Yup.string().required("Please Enter The Future Plans"),
    }),
    onSubmit: (values) => {
      let portfolio = { ...values, numFloors: values['floors'], currency: 'GBP' };
      dispatch(addToPortfolio(portfolio));
    }
  });

  useEffect(() => {
    if (showToast === true && error) {
      toast.error(error);
      setTimeout(() => dispatch(resetPortfolioToast()), 5000);
    }

    if (showToast === true && !error) {
      toast.success("Portfolio added successfully");
      setModal();
      validation.resetForm();
      setTimeout(() => dispatch(resetPortfolioToast()), 5000);
    }
  }, [showToast]);

  useEffect(() => {
    if (showToast === false) dispatch(getPortfolio({ itemsPerPage: perPageData, page: currentPage, sortKey: 'updatedAt', order: 'asc' }));
  }, [dispatch, currentPage, showToast]);

  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }
  return (
    <React.Fragment>
      <Col xl={12} lg={12}>
        <Card>
          {/* <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All Post
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Archive
              </NavLink>
            </NavItem>
          </ul> */}
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <Row className="justify-content-center">
                  <Col xl={12}>
                    <div>
                      <div className="row align-items-center">
                        <div className="col-12">
                          <div>
                            <button type="button" className="btn btn-secondary h-100 w-100" onClick={() => addToFavorite()}><i className="bx bx-add-to-queue align-middle"></i>Add New Portfolio</button>
                          </div>
                        </div>

                        {/* <Col xs={8}>
                          <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink
                                  className="disabled"
                                  to="#"
                                  tabIndex="-1"
                                >
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link className="nav-link" to="/blog-list">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link
                                  to="/blog-grid"
                                  className="nav-link active"
                                >
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </Col> */}
                      </div>

                      <hr className="mb-4" />
                      {
                        loading ? <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" />
                          :
                          <>
                            <Row>
                              {(portfolioData && portfolioData.items || []).map((item, key) => (
                                <Col xl={4} md={6} key={key}>
                                  <Card className="p-1 border shadow-none">
                                    {/* <div className="position-relative">
                                      <i className="bx bx-buildings fs-1"></i>
                                    </div> */}

                                    <div className="p-2">
                                      <h5>{item.address}</h5>
                                      <p className="text-muted mb-0">{moment(item.updatedAt).format('MMMM Do YYYY')} {item.propertyType ? <span className="badge rounded-1 badge-soft-warning">{item.propertyType.toUpperCase()}</span> : null}</p>
                                    </div>
                                    <hr className="my-2" />
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Purchase price:</strong> {item.purchasePrice} {item.currency}</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Market value:</strong> {item.value} {item.currency}</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Rent per month:</strong> {item.rent} {item.currency}</p>
                                      </li>
                                    </ul>
                                    <hr className="my-2" />
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Number of floors:</strong> {item.numFloors}</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Number of bedrooms:</strong> {item.bedrooms}</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Number of bathrooms:</strong> {item.bathrooms}</p>
                                      </li>
                                    </ul>
                                    <hr className="my-2" />
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Expenses per month:</strong> {item.expenses}</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Gross yield:</strong> {item.grossYield}%</p>
                                      </li>
                                      <br />
                                      <li className="list-inline-item">
                                        <p className="text-muted fs-14 mb-0"><strong>Occupancy rate:</strong> {item.occupancyRate}%</p>
                                      </li>
                                    </ul>
                                    <hr className="my-2" />
                                    <div className="p-1 align-items-center">
                                      <p className="mb-0 text-align-center"><strong>Renovations</strong></p>
                                    </div>
                                    <hr className="my-2" />
                                    {item.renovations !== "" && item.renovations.toLowerCase() !== "none" ?
                                      <div className="p-1">
                                        <p className="mb-0">{item.renovations}</p>
                                      </div> : null}
                                    <div className="p-1 align-items-center">
                                      <p className="mb-0 text-align-center"><strong>Future Plans</strong></p>
                                    </div>
                                    <hr className="my-2" />
                                    {item.futurePlans !== "" && item.futurePlans.toLowerCase() !== "none" ?
                                      <div className="p-1">
                                        <p className="mb-0">{item.futurePlans}</p>
                                      </div> : null}
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                            {portfolioData && portfolioData.items ? <Paginations
                              perPageData={perPageData}
                              data={portfolioData.items}
                              totalItems={portfolioData.totalItems}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              lastPage={portfolioData.lastPage}
                              isShowingPageLength={true}
                              paginationDiv="col-sm-12 col-md-7"
                              paginationClass="pagination justify-content-end pagination-rounded"
                            /> : null}
                          </>
                      }

                      <hr className="my-4" />

                      {/* <div className="text-center">
                        <ul className="pagination justify-content-center pagination-rounded">
                          <li className="page-item disabled">
                            <Link to="#" className="page-link">
                              <i className="mdi mdi-chevron-left"></i>
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="page-link">
                              1
                            </Link>
                          </li>
                          <li className="page-item active">
                            <Link to="#" className="page-link">
                              2
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="page-link">
                              3
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="page-link">
                              ...
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="page-link">
                              10
                            </Link>
                          </li>
                          <li className="page-item">
                            <Link to="#" className="page-link">
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tabId="2">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <h5>Archive</h5>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2020</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            03
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i>{" "}
                          Beautiful Day with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2019</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            06
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Coffee
                          with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Neque
                          porro quisquam est
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Quis
                          autem vel eum iure
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Cras mi
                          eu turpis
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2018</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            03
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i>{" "}
                          Beautiful Day with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Col>

      {showToast && <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />}

      <Modal size="lg" isOpen={modal} toggle={() => { setModal(); }} id="applyJobs">
        <div className="modal-content">
          <ModalHeader toggle={() => setModal()} id="applyJobsLabel" className="modal-header">
            Add New Portfolio
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={validation.handleSubmit} autoComplete='off'>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label for="address">Address</Label>
                    <Input type="text" id="address" name="address" placeholder="Enter the address" value={validation.values.address} onChange={validation.handleChange} />
                    {
                      validation.touched.address && validation.errors.address ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.address}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label for="propertyType">Property Type</Label>
                    <Input type="text" id="propertyType" name="propertyType" placeholder="Enter the portfolio type" value={validation.values.propertyType} onChange={validation.handleChange} />
                    {
                      validation.touched.propertyType && validation.errors.propertyType ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.propertyType}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label for="uniqueFeature">Unique Feature</Label>
                    <Input type="text" id="uniqueFeature" name="uniqueFeature" placeholder="Enter any unique feature" value={validation.values.uniqueFeature} onChange={validation.handleChange} />
                    {
                      validation.touched.uniqueFeature && validation.errors.uniqueFeature ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.uniqueFeature}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="floors">Number Of Floors</Label>
                    <Input type="number" id="floors" name="floors" placeholder="Enter the number of floors" value={validation.values.floors} onChange={validation.handleChange} />
                    {
                      validation.touched.floors && validation.errors.floors ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.floors}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="bedrooms">Number Of Bedrooms</Label>
                    <Input type="number" id="bedrooms" name="bedrooms" placeholder="Enter the number of bedrooms" value={validation.values.bedrooms} onChange={validation.handleChange} />
                    {
                      validation.touched.bedrooms && validation.errors.bedrooms ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.bedrooms}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="bathrooms">Number Of Bathrooms</Label>
                    <Input type="number" id="bathrooms" name="bathrooms" placeholder="Enter the number of bathrooms" value={validation.values.bathrooms} onChange={validation.handleChange} />
                    {
                      validation.touched.bathrooms && validation.errors.bathrooms ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.bathrooms}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="purchasePrice">Purchase Price</Label>
                    <Input type="number" id="purchasePrice" name="purchasePrice" placeholder="Enter the purchase price" value={validation.values.purchasePrice} onChange={validation.handleChange} />
                    {
                      validation.touched.purchasePrice && validation.errors.purchasePrice ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.purchasePrice}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="value">Market Value</Label>
                    <Input type="number" id="value" name="value" placeholder="Enter the market value" value={validation.values.value} onChange={validation.handleChange} />
                    {
                      validation.touched.value && validation.errors.value ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.value}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="rent">Rent Per Month</Label>
                    <Input type="number" id="rent" name="rent" placeholder="Enter the rent per month" value={validation.values.rent} onChange={validation.handleChange} />
                    {
                      validation.touched.rent && validation.errors.rent ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.rent}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="expenses">Expense Per Month</Label>
                    <Input type="number" id="expenses" name="expenses" placeholder="Enter the expenses per month" value={validation.values.expenses} onChange={validation.handleChange} />
                    {
                      validation.touched.expenses && validation.errors.expenses ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.expenses}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="grossYield">Gross Yield</Label>
                    <Input type="number" id="grossYield" name="grossYield" placeholder="Enter the gross yield" value={validation.values.grossYield} onChange={validation.handleChange} />
                    {
                      validation.touched.grossYield && validation.errors.grossYield ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.grossYield}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-3">
                    <Label for="occupancyRate">Occupancy Rate</Label>
                    <Input type="number" id="occupancyRate" name="occupancyRate" placeholder="Enter the occupancy rate" value={validation.values.occupancyRate} onChange={validation.handleChange} />
                    {
                      validation.touched.occupancyRate && validation.errors.occupancyRate ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.occupancyRate}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <Label for="renovations">Renovations</Label>
                    <textarea className="form-control" id="renovations" name="renovations" rows="3" placeholder="Enter recent renovations on this portfolio" value={validation.values.renovations} onChange={validation.handleChange}></textarea>
                    {
                      validation.touched.renovations && validation.errors.renovations ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.renovations}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <Label for="futurePlans">Future Plans</Label>
                    <textarea className="form-control" id="futurePlans" name="futurePlans" rows="3" placeholder="Enter your future plans for this portfolio" value={validation.values.futurePlans} onChange={validation.handleChange}></textarea>
                    {
                      validation.touched.futurePlans && validation.errors.futurePlans ?
                        <FormFeedback type="invalid" className='d-block'>{validation.errors.futurePlans}</FormFeedback>
                        : null
                    }
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="text-end">
                    <button type='submit' className="btn btn-secondary me-1">Submit</button>
                    <button type="button" className="btn btn-outline-dark" onClick={() => setModal()}>Cancel</button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}
export default BlogGrid
