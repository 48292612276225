import React from "react";
import { Navigate } from "react-router-dom";

const NonProtectedAuth = (props) => {
  if (localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
    );
  }

  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default NonProtectedAuth;
