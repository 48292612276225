import {
  GET_PORTFOLIO,
  GET_PORTFOLIO_FAIL,
  GET_PORTFOLIO_SUCCESS,
  ADD_TO_PORTFOLIO,
  ADD_TO_PORTFOLIO_SUCCESS,
  ADD_TO_PORTFOLIO_FAIL,
  RESET_TOAST
} from "./actionTypes";

export const resetPortfolioToast = () => {
  return {
    type: RESET_TOAST
  };
}

export const getPortfolio = (data) => {
  return {
    type: GET_PORTFOLIO,
    payload: { data }
  };
}

export const getPortfolioSuccess = portfolioData => {
  return {
    type: GET_PORTFOLIO_SUCCESS,
    payload: portfolioData,
  }
}

export const getPortfolioFail = error => ({
  type: GET_PORTFOLIO_FAIL,
  payload: error,
})

export const addToPortfolio = data => ({
  type: ADD_TO_PORTFOLIO,
  payload: data,
})

export const addToPortfolioSuccess = () => ({
  type: ADD_TO_PORTFOLIO_SUCCESS
})

export const addToPortfolioFail = error => ({
  type: ADD_TO_PORTFOLIO_FAIL,
  payload: error,
})