import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PORTFOLIO, ADD_TO_PORTFOLIO } from "./actionTypes";
import {
  getPortfolioSuccess,
  getPortfolioFail,
  addToPortfolioSuccess,
  addToPortfolioFail
} from "./actions"

import {
  getPortfolios,
  addNewPortfolio
} from "helpers/fakebackend_helper";

function* fetchPortfolios({ payload: { data } }) {
  try {
    const response = yield call(getPortfolios, data);
    yield put(getPortfolioSuccess(response));
  } catch (error) {
    yield put(getPortfolioFail(error));
  }
}

function* onAddToPortfolio({ payload: data }) {
  try {
      const response = yield call(addNewPortfolio, data)
      yield put(addToPortfolioSuccess(response));
  } catch (error) {
      yield put(addToPortfolioFail(error));
  }
}

function* jobsSaga() {
  yield takeEvery(GET_PORTFOLIO, fetchPortfolios)
  yield takeEvery(ADD_TO_PORTFOLIO, onAddToPortfolio)
}

export default jobsSaga;