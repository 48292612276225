import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Table,
  Spinner
} from "reactstrap";
import Select from "react-select";
import moment from "moment";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {

  //meta title
  document.title = "Profile | Property 25 | Your Dream Property";

  const dispatch = useDispatch();

  const [link, setLink] = useState(avatar);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [subscription, setSubscription] = useState("");
  const [onTrial, setOnTrial] = useState("");
  const [billPaid, setBillPaid] = useState("");
  const [trialExpiry, setTrialExpiry] = useState("");
  const [lastPayment, setLastPayment] = useState("");
  const [showSubscription, setShowSubscription] = useState(false);
  const [idx, setIdx] = useState(0);

  const selectProfileState = (state) => state.Profile;
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({ error: profile.error, success: profile.success, loading: profile.loading }));
  const { error, success, loading } = useSelector(ProfileProperties);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setUserName(obj.displayName);
        setEmail(obj.email);
        setIdx(obj.uid);
      } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        setUserName(obj.displayName);
        setEmail(obj.email);
        setIdx(obj.uid);
      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt" ) {
        setUserName(obj.userName);
        setEmail(obj.email);
        setFirstName(obj.firstName);
        setLastName(obj.lastName);
        setRole(obj.role);
        setSubscription(obj.subscription);
        setIdx(obj.id);

        setOnTrial(obj.onTrial ? "Yes" : "No");
        setBillPaid(obj.billPaid ? "Yes" : "No");
        setTrialExpiry(moment(obj.trialExpiry).format('MMMM Do YYYY'));
        setLastPayment(obj.lastPayment === "" ? null : moment(obj.lastPayment).format('MMMM Do YYYY'));
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      idx: idx || '',
      email: email || '',
      userName: userName || '',
      firstName: firstName || '',
      lastName: lastName || '',
      role: role || '',
      subscription: subscription || ''
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your User Name"),
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile({ userName: values.userName, firstName: values.firstName, lastName: values.lastName }));
    }
  });

  const subscriptionOptions = [
    {
      label: "Subscription Levels",
      options: [
        { label: "Silver - $25", value: "Silver" },
        { label: "Gold - $40", value: "Gold" },
        { label: "Platinum - $50", value: "Platinum" }
      ]
    }
  ];

  const defaultValue = (subscription, value) => {
    return subscription && subscription[0] && subscription[0].options ? subscription[0].options.find(option => option.value === value) : '';
  };

  function handleSubscription() {
    setShowSubscription(false);
    console.log(validation.values.subscription);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Property 25" breadcrumbItem="Update Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-2 me-2">
                      <img
                        src={link}
                        alt={email}
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted text-truncate">
                        <p className="mb-1"><strong>Role:</strong> {role}</p>
                        <p className="mb-1"><strong>Email:</strong> {email}</p>
                        <p className="mb-1"><strong>Id #:</strong> {idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Card>
            <CardBody>
              <CardTitle className="mb-4">Personal Information</CardTitle>
              <p className="text-muted mb-4">
                I am a Software Engineer
              </p>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Full Name :</th>
                      <td>{userProfile.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile :</th>
                      <td>{userProfile.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">E-mail :</th>
                      <td>{userProfile.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">Location :</th>
                      <td>{userProfile.location}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card> */}

          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          {
            loading ? <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" />
              :
              <Card>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {!showSubscription ? <div className="form-group">
                      <Label className="form-label" for="userName">User Name</Label>
                      <Input
                        id="userName"
                        name="userName"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.userName || ""}
                        invalid={
                          validation.touched.userName && validation.errors.userName ? true : false
                        }
                      />
                      {validation.touched.userName && validation.errors.userName ? (
                        <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                      ) : null}
                    </div> : null}
                    {!showSubscription ? <div className="form-group">
                      <Label className="form-label" for="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName && validation.errors.firstName ? true : false
                        }
                      />
                      {validation.touched.firstName && validation.errors.firstName ? (
                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                      ) : null}
                    </div> : null}
                    {!showSubscription ? <div className="form-group">
                      <Label className="form-label" for="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                          validation.touched.lastName && validation.errors.lastName ? true : false
                        }
                      />
                      {validation.touched.lastName && validation.errors.lastName ? (
                        <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                      ) : null}
                      <Input name="idx" value={idx} type="hidden" />
                    </div> : null}
                    {showSubscription ? <div className="mb-3">
                      <Label className="form-label">Subscription</Label>
                      <Select
                        className="select2-selection"
                        name="subscription"
                        type="subscription"
                        placeholder="Select a subscription Level"
                        value={defaultValue(subscriptionOptions, validation.values.subscription)}
                        onChange={e => validation.setFieldValue('subscription', e.value)}
                        options={subscriptionOptions}
                        invalid={
                          validation.touched.subscription && validation.errors.subscription ? true : false
                        }
                      />
                      {validation.touched.subscription && validation.errors.subscription ? (
                        <FormFeedback type="invalid">{validation.errors.subscription}</FormFeedback>
                      ) : null}
                    </div> : null}
                    {showSubscription ? <div className="mb-3 row">
                      <div className="col-md-6">
                        <p><strong>On Trial:</strong> {onTrial}</p>
                        <p><strong>Trial Expiry:</strong> {trialExpiry}</p>
                      </div>
                      <div className="col-md-6">
                        <p><strong>Bill Paid:</strong> {billPaid}</p>
                        <p><strong>Last Payment:</strong> {lastPayment}</p>
                      </div>
                    </div> : null}
                    <div className="text-center mt-4">
                      {!showSubscription ? <Button type="button" className="me-3" color="secondary" onClick={(e) => { e.preventDefault(); setShowSubscription(true); return false; }}>
                        Modify Subscription
                      </Button> : null}
                      {showSubscription ? <Button type="button" className="me-3" color="secondary" onClick={(e) => { e.preventDefault(); setShowSubscription(false); return false; }}>
                        Modify Profile
                      </Button> : null}
                      {showSubscription ? <Button type="button" className="me-3" color="danger" onClick={(e) => { e.preventDefault(); handleSubscription(); return false; }}>
                        Update Subscription
                      </Button> : null}
                      {showSubscription ? <Link to="/make-payment" className="me-3 btn btn-warning">
                        Make Payment{" "}
                      </Link> : null}
                      {!showSubscription ? <Button type="submit" className="me-3" color="danger">
                        Update Profile
                      </Button> : null}
                      <Link to="/profile" className="btn btn-secondary">
                        Discard Changes{" "}
                      </Link>
                    </div>
                  </Form>
                </CardBody>
              </Card>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
