import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Overview from './Overview';
import DetailsSection from './DetailsSection';

import { getPropertyForDetails } from "../../../store/actions";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const JobDetails = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPropertyForDetails());
    }, [dispatch]);

    const selectJobReducerState = (state) => state.JobReducer;
    const JobProperties = createSelector(selectJobReducerState, (JobReducer) => ({
        error: JobReducer.error,
        loading: JobReducer.loading,
        property: JobReducer.property
    }));

    const { error, loading, property } = useSelector(JobProperties);

    document.title = "Property Details | Property 25 | Your Dream Property";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Property 25" breadcrumbItem="Property Details" />

                    <Row>
                        {property ? <Overview property={property}/> : null}
                        {property ? <DetailsSection property={property}/> : null}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default JobDetails;