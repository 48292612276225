import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Label, Input, Form, Collapse, FormFeedback, Spinner, Button } from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import _ from "lodash";

// format number
import { PatternFormat } from "react-number-format";

import 'react-toastify/dist/ReactToastify.css';
// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { experienceData, jobType } from 'common/data';
// import Spinners from 'components/Common/Spinner';
import Paginations from 'components/Common/Pagination';

import { ToastContainer, toast } from 'react-toastify';

import { getJobList, setPropertyForDetails, addToChats, resetChat, resetToast, addNewPortfolio, setReservePropertyTwo } from "../../../store/actions";

const JobData = props => {
    const [modal, setModal] = useState(false);
    const [selectDate, setSelectDate] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [zpId, setZpid] = useState(null);
    const [address, setAddress] = useState('');
    const [property, setProperty] = useState({});
    const [reservedProperty, setReservedProperty] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectJobReducerState = (state) => state.JobReducer;
    const JobProperties = createSelector(selectJobReducerState, (JobReducer) => ({
        error: JobReducer.error,
        loading: JobReducer.loading,
        jobData: JobReducer.jobData,
        page: JobReducer.page,
        setPage: JobReducer.setPage,
        chat: JobReducer.chat,
        showToast: JobReducer.showToast,
        reservePropertyOne: JobReducer.reservePropertyOne,
        reservePropertyTwo: JobReducer.reservePropertyTwo
    }));

    const { error, loading, jobData, page, setPage, chat, showToast, reservePropertyOne, reservePropertyTwo } = useSelector(JobProperties);

    const [currentPage, setCurrentPage] = useState(setPage ? page : 1);
    const perPageData = 8;

    function addToFavorite(item) {
        if (item.grossYield) item.yield = item.grossYield.split('%')[0];
        setProperty(item);
        setModal(true);
    }

    function addChat(item) {
        const user = JSON.parse(localStorage.getItem("authUser"));
        dispatch(addToChats({ userId: user.id, propertyId: item._id, group: item.address }));
    }

    function getPropertyDetails(item) {
        dispatch(setPropertyForDetails({ item, currentPage }));
        navigate("/property-details");
    }

    function compareProperty(item) {
        dispatch(setReservePropertyTwo(item));
        navigate("/compare-properties");
    }

    function findProperties() {
        dispatch(getJobList({ itemsPerPage: perPageData, page: currentPage, sortKey: 'rent', order: 'desc', zpId: zpId, address: address }));
    }

    const handleAddress = (e) => { e.isDefaultPrevented(); setAddress(e.target.value); };
    const handleZpid = (e) => { e.isDefaultPrevented(); if (e.target.value === '') setZpid(null); else setZpid(e.target.value); };

    const dateChange = (date) => { setSelectDate(date); };

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        dispatch(getJobList({ itemsPerPage: perPageData, page: currentPage, sortKey: 'rent', order: 'desc', zpId: zpId, address: address }));
    }, [dispatch, currentPage]);

    useEffect(() => {
        if (chat !== null) {
            navigate("/chat");
        }
        dispatch(resetChat());
    }, [dispatch, chat]);

    useEffect(() => {
        if (_.isEmpty(reservePropertyTwo) && !_.isEmpty(reservePropertyOne)) {
            setReservedProperty(reservePropertyOne);
        } else {
            dispatch(setReservePropertyTwo(null));
            setReservedProperty({});
        }
    }, [reservePropertyOne]);

    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: property.address || '',
            propertyType: property.propertyType || '',
            floors: property.numFloors || 1,
            bedrooms: property.bedrooms || 1,
            bathrooms: property.bathrooms || 1,
            uniqueFeature: property.category || '',
            purchasePrice: parseFloat(property.price) || 0,
            value: parseFloat(property.price) || 0,
            rent: property.rent || 0,
            grossYield: parseFloat(property.yield) || 0,
            expenses: 0,
            occupancyRate: 0,
            renovations: '',
            futurePlans: ''
        },
        validationSchema: Yup.object({
            address: Yup.string().required("Please Enter The Address"),
            propertyType: Yup.string().required("Please Enter The Property Type"),
            floors: Yup.string().required("Please Enter The Number Of Floors"),
            bedrooms: Yup.string().required("Please Enter The Number Of Bedrooms"),
            bathrooms: Yup.string().required("Please Enter The Number Of Bathrooms"),
            uniqueFeature: Yup.string().required("Please Enter Any Unique Feature"),
            purchasePrice: Yup.string().required("Please Enter The Purchase Price"),
            value: Yup.string().required("Please Enter The Current Market Value"),
            rent: Yup.string().required("Please Enter The Rent"),
            grossYield: Yup.string().required("Please Enter The Gross Yield"),
            expenses: Yup.string().required("Please Enter The Expenses Per Month"),
            occupancyRate: Yup.string().required("Please Enter The Occupancy Rates"),
            renovations: Yup.string().required("Please Enter The Recent Renovations"),
            futurePlans: Yup.string().required("Please Enter The Future Plans"),
        }),
        onSubmit: (values) => {
            let portfolio = { ...values, numFloors: values['floors'], zpId: property.zpId, currency: 'GBP' };
            dispatch(addNewPortfolio(portfolio));
        }
    });

    useEffect(() => {
        if (showToast === true && error) {
            toast.error(error);
            setTimeout(() => dispatch(resetToast()), 5000);
        }

        if (showToast === true && !error) {
            toast.success("Portfolio added successfully");
            setModal();
            validation.resetForm();
            setTimeout(() => dispatch(resetToast()), 5000);
        }
    }, [showToast]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="job-filter">
                        <CardBody>
                            <Form>
                                <Row className="g-3">
                                    <Col xxl={4} lg={4}>
                                        <div className="position-relative">
                                            <Input type="text" className="form-control" id="searchJob" value={zpId || ""} onChange={handleZpid} placeholder="Property Number" />
                                        </div>
                                    </Col>

                                    <Col xxl={2} lg={4}>
                                        <div className="position-relative">
                                            <Input type="text" className="form-control" id="locationInput" value={address || ""} onChange={handleAddress} placeholder="New Yorkshire" />
                                        </div>
                                    </Col>

                                    <Col xxl={2} lg={4}>
                                        <div className="position-relative">
                                            <div id="datepicker1">
                                                <FlatPickr
                                                    id="timeInput"
                                                    className="form-control"
                                                    placeholder="Select Time"
                                                    options={{ dateFormat: "d M,Y" }}
                                                    selected={selectDate}
                                                    onChange={dateChange}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col xxl={2} lg={6}>

                                    </Col> */}

                                    <Col xxl={12} lg={12}>
                                        <div className="position-relative h-100 hstack gap-3">
                                            <button type="button" className="btn btn-secondary h-100 w-100" onClick={() => findProperties()}><i className="bx bx-search-alt align-middle"></i>Find Properties</button>
                                            {/* <a href="#" onClick={toggle} className="btn btn-secondary h-100 w-100"><i className="bx bx-filter-alt align-middle"></i> Advance</a> */}
                                        </div>
                                    </Col>

                                    <Collapse isOpen={isOpen} id="collapseExample">
                                        <div>
                                            <Row className="g-3">
                                                <Col xxl={4} lg={6}>
                                                    <div>
                                                        <p className="form-label fw-semibold">Experience</p>
                                                    </div>
                                                    {
                                                        (experienceData || []).map((item, index) => (
                                                            <div className="form-check form-check-inline" key={index}>
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                                <Label className="form-check-label" for={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                            </div>
                                                        ))
                                                    }
                                                </Col>
                                                <Col xxl={4} lg={6}>
                                                    <div>
                                                        <p className="form-label fw-semibold">Job Type</p>
                                                    </div>
                                                    {
                                                        (jobType || []).map((item, index) => (
                                                            <div className="form-check form-check-inline" key={index}>
                                                                <Input type="checkbox" id={`inlineCheckbox${item.id}`} value={item.value} />
                                                                <Label className="form-check-label" for={`inlineCheckbox${item.id}`}>{item.label}</Label>
                                                            </div>
                                                        ))
                                                    }
                                                </Col>
                                                <Col xxl={4} lg={4}>
                                                    <div className="position-relative">
                                                        <Label for="qualificationInput" className="fw-semibold">Qualification</Label>
                                                        <Input type="text" id="qualificationInput" autoComplete="off" placeholder="Qualification" />
                                                        <i className="ri-government-line filter-icon"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Collapse>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                loading ? <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" />
                    :
                    <>
                        <Row>
                            {(jobData && jobData.items ? jobData.items : []).map((item, key) => (
                                <Col xl={3} md={6} key={key}>
                                    <Card>
                                        <CardBody>
                                            <img src={item.imgSrc} alt="" height="150" width="200" className="mb-3" />
                                            <h5 className="fs-17 mb-2">
                                                <p className="text-dark">{item.address} <small className="text-muted fw-normal">({item.zpId})</small></p>
                                            </h5>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item w-lg">
                                                    <p className="text-muted fs-14 mb-1">{item.brokerName}</p>
                                                </li>{" "}
                                                {item.livingArea && item.livingArea.min_floor_area && item.livingArea.min_floor_area.value && <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0"><i className="bx bxs-area"></i> {item.livingArea.min_floor_area.value} {item.livingArea.min_floor_area.units}</p>
                                                </li>}
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0"><i className="mdi mdi-map-marker"></i>({item.latitude}, {item.longitude})</p>
                                                </li>
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0"><i className="bx bxs-wallet"></i> {item.price} {item.statusType.includes('RENT') ? ' / month' : ''} </p>
                                                </li>
                                            </ul>
                                            <div className="mt-3 hstack gap-2">
                                                {item.statusType ? <span className="badge rounded-1 badge-soft-success">{item.statusType.split('_').join(' ').toUpperCase()}</span> : null}
                                                {item.category ? <span className="badge rounded-1 badge-soft-info">{item.category.toUpperCase()}</span> : null}
                                            </div>
                                            {item.propertyType ? <div className="mt-3 hstack gap-2">
                                                <span className="badge rounded-1 badge-soft-warning">{item.propertyType.toUpperCase()}</span>
                                            </div> : null}
                                            <div className="mt-4 hstack gap-2">
                                                {_.isEmpty(reservedProperty) ? <Button type="button" onClick={() => getPropertyDetails(item)} className="btn btn-soft-info w-100">View Detail</Button>
                                                    : <Button type="button" onClick={() => compareProperty(item)} className="btn btn-soft-info w-100">Compare</Button>}
                                                <Button type="button" onClick={() => addChat(item)} className="btn btn-soft-primary"><i className="bx bx-chat fs-5"></i></Button>
                                                <Button type="button" onClick={() => addToFavorite(item)} className="btn btn-soft-success"><i className="bx bx-plus-medical fs-5"></i></Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        {jobData && jobData.items ? <Paginations
                            perPageData={perPageData}
                            data={jobData.items}
                            totalItems={jobData.totalItems}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            lastPage={jobData.lastPage}
                            isShowingPageLength={true}
                            paginationDiv="col-sm-12 col-md-7"
                            paginationClass="pagination justify-content-end pagination-rounded"
                        /> : null}
                    </>
            }

            {showToast && <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />}

            {/* Modal */}
            <Modal size="lg" isOpen={modal} toggle={() => { setModal(); }} id="applyJobs">
                <div className="modal-content">
                    <ModalHeader toggle={() => setModal()} id="applyJobsLabel" className="modal-header">
                        Add To Portfolio
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={validation.handleSubmit} autoComplete='off'>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label for="address">Address</Label>
                                        <Input type="text" id="address" name="address" placeholder="Enter the address" value={validation.values.address} onChange={validation.handleChange} disabled={true} />
                                        {
                                            validation.touched.address && validation.errors.address ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.address}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label for="propertyType">Property Type</Label>
                                        <Input type="text" id="propertyType" name="propertyType" placeholder="Enter the property type" value={validation.values.propertyType} onChange={validation.handleChange} />
                                        {
                                            validation.touched.propertyType && validation.errors.propertyType ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.propertyType}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label for="uniqueFeature">Unique Feature</Label>
                                        <Input type="text" id="uniqueFeature" name="uniqueFeature" placeholder="Enter any unique feature" value={validation.values.uniqueFeature} onChange={validation.handleChange} />
                                        {
                                            validation.touched.uniqueFeature && validation.errors.uniqueFeature ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.uniqueFeature}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="floors">Number Of Floors</Label>
                                        <Input type="number" id="floors" name="floors" placeholder="Enter the number of floors" value={validation.values.floors} onChange={validation.handleChange} />
                                        {
                                            validation.touched.floors && validation.errors.floors ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.floors}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="bedrooms">Number Of Bedrooms</Label>
                                        <Input type="number" id="bedrooms" name="bedrooms" placeholder="Enter the number of bedrooms" value={validation.values.bedrooms} onChange={validation.handleChange} />
                                        {
                                            validation.touched.bedrooms && validation.errors.bedrooms ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.bedrooms}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="bathrooms">Number Of Bathrooms</Label>
                                        <Input type="number" id="bathrooms" name="bathrooms" placeholder="Enter the number of bathrooms" value={validation.values.bathrooms} onChange={validation.handleChange} />
                                        {
                                            validation.touched.bathrooms && validation.errors.bathrooms ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.bathrooms}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="purchasePrice">Purchase Price</Label>
                                        <Input type="number" id="purchasePrice" name="purchasePrice" placeholder="Enter the purchase price" value={validation.values.purchasePrice} onChange={validation.handleChange} />
                                        {
                                            validation.touched.purchasePrice && validation.errors.purchasePrice ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.purchasePrice}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="value">Market Value</Label>
                                        <Input type="number" id="value" name="value" placeholder="Enter the market value" value={validation.values.value} onChange={validation.handleChange} />
                                        {
                                            validation.touched.value && validation.errors.value ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.value}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="rent">Rent Per Month</Label>
                                        <Input type="number" id="rent" name="rent" placeholder="Enter the rent per month" value={validation.values.rent} onChange={validation.handleChange} />
                                        {
                                            validation.touched.rent && validation.errors.rent ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.rent}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="expenses">Expense Per Month</Label>
                                        <Input type="number" id="expenses" name="expenses" placeholder="Enter the expenses per month" value={validation.values.expenses} onChange={validation.handleChange} />
                                        {
                                            validation.touched.expenses && validation.errors.expenses ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.expenses}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="grossYield">Gross Yield</Label>
                                        <Input type="number" id="grossYield" name="grossYield" placeholder="Enter the gross yield" value={validation.values.grossYield} onChange={validation.handleChange} />
                                        {
                                            validation.touched.grossYield && validation.errors.grossYield ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.grossYield}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label for="occupancyRate">Occupancy Rate</Label>
                                        <Input type="number" id="occupancyRate" name="occupancyRate" placeholder="Enter the occupancy rate" value={validation.values.occupancyRate} onChange={validation.handleChange} />
                                        {
                                            validation.touched.occupancyRate && validation.errors.occupancyRate ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.occupancyRate}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-4">
                                        <Label for="renovations">Renovations</Label>
                                        <textarea className="form-control" id="renovations" name="renovations" rows="3" placeholder="Enter recent renovations on this property" value={validation.values.renovations} onChange={validation.handleChange}></textarea>
                                        {
                                            validation.touched.renovations && validation.errors.renovations ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.renovations}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-4">
                                        <Label for="futurePlans">Future Plans</Label>
                                        <textarea className="form-control" id="futurePlans" name="futurePlans" rows="3" placeholder="Enter your future plans for this property" value={validation.values.futurePlans} onChange={validation.handleChange}></textarea>
                                        {
                                            validation.touched.futurePlans && validation.errors.futurePlans ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.futurePlans}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="text-end">
                                        <button type='submit' className="btn btn-secondary me-1">Submit</button>
                                        <button type="button" className="btn btn-outline-dark" onClick={() => setModal()}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default JobData;