import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Card, CardBody, Col } from 'reactstrap';
import moment from 'moment';

import { setBackToProperties, addToChats, resetChat } from "../../../store/actions";

const Overview = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectJobReducerState = (state) => state.JobReducer;
    const JobProperties = createSelector(selectJobReducerState, (JobReducer) => ({
        chat: JobReducer.chat
    }));

    const { chat } = useSelector(JobProperties);

    function getProperties() {
        dispatch(setBackToProperties(true));
        navigate("/properties");
    }

    function addChat(item) {
        const user = JSON.parse(localStorage.getItem("authUser"));
        dispatch(addToChats({ userId: user.id, propertyId: item._id, group: item.address }));
    }

    useEffect(() => {
        if (chat !== null) {
            navigate("/chat");
        }
        dispatch(resetChat());
    }, [dispatch, chat]);

    function addToFavorite(item) {
        console.log(item);
    }

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody className="border-bottom">
                        <div className="text-center">
                            <img className="img-fluid" src={props.property.imgSrc} alt="" />
                        </div>
                        <div className=''>
                            <img src="https://www.zoopla.co.uk/static/images/mashery/powered-by-zoopla-150x73.png" width="60" height="30" title="Property information powered by Zoopla" alt="Property information powered by Zoopla" border="0" />
                        </div>
                    </CardBody>
                    <CardBody>
                        <p className="text-muted">Click here for more details: </p><a href={props.property.detailUrl} target="_blank" rel="noreferrer">{props.property.detailUrl}</a>
                        <br /><br />
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <h5 className="fw-semibold">Overview</h5>

                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Address</th>
                                        <td scope="col">{props.property.address}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Broker</th>
                                        <td>{props.property.brokerName}</td>
                                    </tr>
                                    {props.property.statusType && <tr>
                                        <th scope="row">Status</th>
                                        <td><span className="badge badge-soft-success">{props.property.statusType.split('_').join(' ').toUpperCase()}</span></td>
                                    </tr>}
                                    {props.property.category && <tr>
                                        <th scope="row">Category</th>
                                        <td><span className="badge badge-soft-info">{props.property.category.toUpperCase()}</span></td>
                                    </tr>}
                                    {props.property.propertyType && <tr>
                                        <th scope="row">Type</th>
                                        <td><span className="badge badge-soft-warning">{props.property.propertyType.toUpperCase()}</span></td>
                                    </tr>}
                                    <tr>
                                        <th scope="row">Posted Date</th>
                                        <td>{moment(props.property.createdAt).format('MMMM Do YYYY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="hstack gap-2">
                            <button onClick={() => getProperties()} className="btn btn-soft-info w-100"><i className="bx bx-arrow-back fs-4"></i></button>
                            <button onClick={() => addChat(props.property)} className="btn btn-soft-primary w-100"><i className="bx bx-chat fs-4"></i></button>
                            <button onClick={() => addToFavorite(props.property)} className="btn btn-soft-success w-100"><i className="bx bx-plus-medical fs-4"></i></button>
                        </div> */}
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <ul className="list-unstyled mt-4">
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-map text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Location</h6>
                                        <p className="text-muted fs-14 mb-0">({props.property.latitude}, {props.property.longitude})</p>
                                    </div>
                                </div>
                            </li>
                            {props.property.livingArea && props.property.livingArea.min_floor_area && <li>
                                <div className="d-flex">
                                    <i className="bx bx-area text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Living Area</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.livingArea.min_floor_area.value} {props.property.livingArea.min_floor_area.units}</p>
                                    </div>
                                </div>
                            </li>}
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-bed text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Bedrooms</h6>
                                        <p className="text-muted fs-14 text-break mb-0">{props.property.bedrooms}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-bath text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Bathrooms</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.bathrooms}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-hotel text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Floors</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.numFloors}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <ul className="list-unstyled mt-4">
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-calculator text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Return on Investment (ROI)</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.grossYield}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-purchase-tag text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Asking Rent</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.rent}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-money text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Cash Flow</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.cashFlow}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-shield text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Cash on Cash Return</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.cashAndCashReturn}%</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-pie-chart text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Loan-to-Value Ratio (LTV - 20% down)</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.loanToValueRatio * 100}%</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    {props.property.currency === 'EUR' ? <i className="bx bx-euro text-primary fs-4"></i> : <i className="bx bx-pound text-primary fs-4"></i>}
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Total Price</h6>
                                        <p className="text-muted fs-14 mb-0">{props.property.price}</p>
                                    </div>
                                </div>
                            </li>
                            {props.property.priceChange && props.property.priceChange.length && props.property.priceChange.length > 0 && <li className="mt-3">
                                <div className="d-flex">
                                    <i className="fas fa-sort-amount-down text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Price Changes</h6>
                                        {/* {
                                            (props.property.priceChange).map((item, index) => (
                                                <p className="text-muted fs-14 mb-0">01- Price change on {item.date}</p>
                                                ))
                                            } */}
                                        <p className="text-muted fs-14 mb-0">01- Price change since {moment(props.property.priceChange[0].date).format('MMMM Do YYYY')} is {props.property.priceChange[0].direction === "" ? "staying at " : props.property.priceChange[0].direction === "up" ? "increased by " : "decreased by "} {props.property.priceChange[0].percent} with the value {props.property.priceChange[0].price}.</p>
                                    </div>
                                </div>
                            </li>}
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-info-circle text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Agent Information</h6>
                                        <p className="text-muted fs-14 mb-0">Agent Id: {props.property.companyId}</p>
                                        <p className="text-muted fs-14 mb-0">Agent Address: {props.property.agentAddress}</p>
                                        <p className="text-muted fs-14 mb-0">Agent Phone #: {props.property.agentPhone}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default Overview;