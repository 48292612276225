import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Row } from "reactstrap";

const Paginations = ({ perPageData, data, currentPage, setCurrentPage, isShowingPageLength, paginationDiv, paginationClass, totalItems, lastPage }) => {

    //pagination

    let pageNumbers = [];

    let totalLength = totalItems || data?.length;
    let showStartDots = false;
    let showLastDots = false;

    let maxPage = currentPage === 1 ? currentPage + 2 : currentPage === Math.ceil(totalLength / perPageData) ? Math.ceil(totalLength / perPageData) : currentPage + 1;
    let startPage = currentPage === 1 ? currentPage : currentPage === Math.ceil(totalLength / perPageData) ? currentPage - 2 : currentPage - 1;

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const handleLastDots = (event) => {
        // console.log(currentPage, startPage, maxPage);
        // pageNumbers = [];
        // startPage = maxPage === 3 ? maxPage + 1 : maxPage === Math.ceil(totalLength / perPageData) ? Math.ceil(totalLength / perPageData) - 2 : maxPage + 1;
        // maxPage = startPage + 2;

        // for (let i = startPage; i <= maxPage; i++) {
        //     pageNumbers.push(i);
        // }

        // showLastDots = maxPage === Math.ceil(totalLength / perPageData) ? false : true;
        // showStartDots = true;
    };

    const handleStartDots = () => {
        // pageNumbers = [];
        // startPage = startPage > 1 ? startPage + 3 : startPage === Math.ceil(totalLength / perPageData) - 2 ? Math.ceil(totalLength / perPageData) - 2 : maxPage + 1;
        // maxPage = startPage + 2;

        // for (let i = startPage; i <= maxPage; i++) {
        //     pageNumbers.push(i);
        // }

        // showLastDots = true;
        // showStartDots = startPage === 1 ? false : true;
    };

    for (let i = startPage; i <= maxPage; i++) {
        if (totalItems > ((i * perPageData) - perPageData)) {
            pageNumbers.push(i);
        }
    }

    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
    };
    const handlenextPage = (event) => {
        event.preventDefault();
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            // setCurrentPage(pageNumbers.length)
        }
    }, [pageNumbers.length, currentPage, setCurrentPage])

    return (
        <React.Fragment>
            <Row className="justify-content-between align-items-center">
                {isShowingPageLength && <div className="col-sm">
                    <div className="text-muted">Showing <span className="fw-semibold">{data?.length}</span> of <span className="fw-semibold">{totalItems || perPageData}</span> entries</div>
                </div>}
                <div className={paginationDiv}>
                    <ul className={paginationClass}>
                        <li className={`page-item ${currentPage <= 1 ? "disabled" : ''}`}>
                            <Link className="page-link" to="#" onClick={() => handleprevPage()}>
                                <i className="mdi mdi-chevron-left"></i>
                            </Link>
                        </li>
                        {showStartDots && <li className="page-item" key="last-dots">
                            <Link className="page-link" to="#" id="last-dots" onClick={(e) => handleStartDots(e)}>
                                ...
                            </Link>
                        </li>}
                        {(pageNumbers || []).map((item, index) => (
                            <li className={currentPage === item ? "page-item active " : "page-item"} key={index}>
                                <Link className="page-link" to="#" id={item} onClick={(e) => handleClick(e)}>
                                    {item}
                                </Link>
                            </li>
                        ))}
                        {showLastDots && <li className="page-item" key="last-dots">
                            <Link className="page-link" to="#" id="last-dots" onClick={(e) => handleLastDots(e)}>
                                ...
                            </Link>
                        </li>}
                        <li className={`page-item ${currentPage >= lastPage ? "disabled" : ''}`}>
                            <Link className="page-link" to="#" onClick={(e) => handlenextPage(e)}>
                                <i className="mdi mdi-chevron-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
}

export default Paginations;