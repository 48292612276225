import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Overview from './Overview';

import { getReservedProperties, setReservePropertyOne, setReservePropertyTwo } from "../../../store/actions";

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const CompareJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getProperties() {
    dispatch(setReservePropertyOne(null));
    dispatch(setReservePropertyTwo(null));
    navigate("/properties");
  }

  useEffect(() => {
    dispatch(getReservedProperties());
  }, [dispatch]);

  const selectJobReducerState = (state) => state.JobReducer;
  const JobProperties = createSelector(selectJobReducerState, (JobReducer) => ({
    error: JobReducer.error,
    loading: JobReducer.loading,
    reservePropertyOne: JobReducer.reservePropertyOne,
    reservePropertyTwo: JobReducer.reservePropertyTwo
  }));

  const { error, loading, reservePropertyOne, reservePropertyTwo } = useSelector(JobProperties);

  document.title = "Compare Properties | Property 25 | Your Dream Property";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Property 25" breadcrumbItem="Compare Properties" />

          <button onClick={() => getProperties()} className="btn btn-secondary w-100 mb-2">Back To Properties</button>
          <Row>
            {reservePropertyOne ? <Overview property={reservePropertyOne} /> : null}
            {reservePropertyTwo ? <Overview property={reservePropertyTwo} /> : null}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CompareJobs;